
.blog-post {
  a {
    text-decoration: none;
    color: $font-color;

    &:hover {
      text-decoration: none;
    }
  }

  &__title {
    a {
      color: $classic-link;
      font-size: 1.2em;
      font-weight: 800;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__date {
    text-transform: uppercase;
    font-size: 0.75em;
  }

  &__excerpt {
    font-size: 0.8rem;
  }
}
