@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&family=Roboto&display=swap');
@import "variables";
@import "colors";
@import "monokai";
@import "font-awesome";
@import "posts";
@import "footer";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

$transition-length: 2s;

body {
  font-size: 1.1rem;
  background-color: $background-color;
  color: $font-color;

  i.fa {
    color: $nav-background-color;
  }

  .grid {
    display: grid;
    margin: 0 auto;
    grid-template-areas:
    "header"
    "content";

    @media (min-width: 800px) {
      grid-template-columns: 1fr 3fr;
      grid-template-areas:
      "header content";
    }

    &__header {
      top: 0;
      left: 0;
      padding-bottom: 1em;
      grid-area: header;
      background-color: $nav-background-color;
      transition: all $transition-length ease;

      @media (min-width: 800px) {
        height: 100%;
        min-height: 100vh;
      }

      img {
        display: block;
        border-radius: 25% 10%;
        border: 2px dashed $font-color;
        width: 20rem;
        margin: 2em auto;
      }

      .nav-item {
        grid-area: nav;
        margin: 20px 0 0 0;
        padding: 0;
        transition: all $transition-length ease;
        display: flex;
        justify-content: center;

        a {
          color: $font-color;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &__main {
      margin-top: 2em;
      grid-area: content;
      padding: 0.5em 2em 0 1em;
      transition: all $transition-length ease;
      display: flex;
      flex-direction: column;
      gap: 2em;
      font-size: 0.9em;

      .blog-content-wrapper {
        display: flex;
        flex-direction: column;
        gap: 2em;
      }

      h1 {
        font-size: 2.0em;
        font-weight: bolder;
      }

      h2 {
        font-size: 1.5;
      }

      h3 {
        font-size: 1.0em;
      }

      h4 {
        font-size: 1.9em;
      }

      h5 {
        font-size: 0.8em;
      }

      ul {
        list-style-type: square;
        margin-left: 4em;
      }

      p {
        font-size: 0.9em;
        img {
          max-width: 100%;
          width: 100%;
          border-radius: 10%;
          border: 2px dotted $font-color;
        }
      }
    }
  }

  pre *, .highlight *, .highlighter-rouge * {
    word-wrap: break-word;
    white-space: pre-wrap;
    font-family: "IBM Plex Mono", monospace;
  }

  #fav-tools {
    list-style: none;
    margin-left: 4em;
    gap: 0.5em;
    display: flex;
    flex-direction: column;
    color: $font-color;
  }

}

