footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 2rem 0 0 0;

  border-top: 1px solid $font-color;
  color: $font-color;

  #social-media {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    a {
      color: $font-color;
    }
  }

  #compiled-on {
    margin: 0 0 5rem 0;
  }
}

